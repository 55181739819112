import React from "react";
import { Router, Switch, Route,useHistory  } from "react-router-dom";
import './App.css';
import './assets/css/styles.css';
import './assets/css/bootstrap.min.css';
import constants from "./assets/constants";
import Error from './views/order/error';
import { isEmpty } from "lodash";
import Orders from './views/order/Orders';
import OrderCompleted from './views/order/OrderCompleted';
import OrderDetail from './views/order/OrderDetail';
import ReceiveData from './views/ReceiveData';;   
export default function Routes(props) {
    let history = useHistory();
    return (
        <>
            <Router history={history}>
                <Switch>
                    <Route path={constants.CheckoutProcess+"/:id"} component={Orders} />
                    <Route path={constants.OrderDetail} component={OrderDetail} />
                    <Route path={constants.OrderComplete} component={OrderCompleted} />
                    <Route path={constants.ErrorPage} component={Error} />
                    <Route path={"/"} component={Orders} />
                </Switch>
            </Router>
            {/* <ReceiveData next={(callBackdata) => {if(!isEmpty(callBackdata.message)) { history.push(constants.ErrorPage); } else history.push({ pathname: constants.CheckoutProcess+"/"+callBackdata.url, search: "", state: { checkoutData: callBackdata.data}});}} /> */}
        </>
    );
}
