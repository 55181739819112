import {UPDATECHECKOUTTOKEN } from '../actions';
const INIT_STATE = {
    checkoutToken: ""
};

const reducer =  (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATECHECKOUTTOKEN:
            return { ...state, checkoutToken: action.payload };
        default: return { ...state };
    }
}
export default reducer;
