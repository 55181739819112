import axios from "axios";
import { isEmpty } from "lodash";
export default class WebHandler {
  CATCH_ERROR = "Catcherror";
  fetchRequests(url, bodyParams, onSuccess, onError, logOut, contentType) {
    var header = {
      "Content-Type":  (isEmpty(contentType) ? "application/x-www-form-urlencoded" : contentType),
    };
    this.serverRequest(url, bodyParams, header, onSuccess, onError, logOut);
  }
  serverRequest(url, bodyParams, header, onSuccess, onError, logOut) {
    axios
      .post(url, bodyParams, {
        headers: {
          ...header,
        },
      })
      .then(async (response) => {
        var responseJson = response.data;
        if (
          responseJson !== undefined &&
          responseJson !== null &&
          responseJson.success === true
        )
        onSuccess(responseJson);
        else {
          onError(responseJson);
        }
      })
      .catch((error) => {
        onError(this.CATCH_ERROR)
      });
  }
  fetchGetRequestWithHeaderAndParameters(url, headerData,parameters, onSuccess, onError, logOut, contentType) {
    var header = {
      "Content-Type": (isEmpty(contentType) ? "application/x-www-form-urlencoded" : contentType),
    };
    header = {...header , ...headerData}
    this.serverGetRequestWithHeaderAndParameters(url, header,parameters, onSuccess, onError, logOut);
  }

  
  serverGetRequestWithHeaderAndParameters(url, header,parameters, onSuccess, onError, logOut) {
    axios
      .get(url, {
        headers: header,
        params: parameters,
      })
      .then(async (response) => {
        var responseJson = response.data;
        if (responseJson && responseJson.success) {
          onSuccess(responseJson);
        }
        else {
          onError(responseJson);
        }
      })
      .catch((error) => {
        onError(this.CATCH_ERROR)
      });
  }
  fetchSimpleGetRequest(url, headerData, onSuccess, onError, logOut, contentType) {
    var header = { "Content-Type": (isEmpty(contentType) ? "application/x-www-form-urlencoded" : contentType)};
    header = {...header , ...headerData}
    this.serverSimpleGetRequest(url, header, onSuccess, onError, logOut);
  }
  fetchGetRequestWithParams(url,params, headerData, onSuccess, onError, logOut, contentType) {
    var header = {
      "Content-Type": (isEmpty(contentType) ? "application/x-www-form-urlencoded" : contentType),
    };
    header = {...header , ...headerData}
    this.serverGetRequestWithParams(url,params, header, onSuccess, onError, logOut);
  }
  serverSimpleGetRequest(url, header, onSuccess, onError, logOut) {
    axios
      .get(url, {
        headers: {
          ...header,
        },
      })
      .then(async (response) => {
        var responseJson = response.data;
        if (responseJson && responseJson.status) {
          onSuccess(responseJson);
        }
        else {
          onError(responseJson);
        }
      })
      .catch((error) => {
        onError(this.CATCH_ERROR)
      });
  }
  serverGetRequestWithParams(url,params, header, onSuccess, onError, logOut) {
    axios
      .get(url, {
        headers: {
          ...header,
        },
        params: {
          ...params
        }
      })
      .then(async (response) => {
        var responseJson = response.data;
        if ( responseJson !== undefined && responseJson !== null && responseJson.success === true ){
          onSuccess(responseJson);
        }
        else {
          onSuccess(responseJson);
        }
      })
      .catch((error) => {
        onError(this.CATCH_ERROR)
      });
  }
}
