import React, { useState, useEffect, useRef } from "react";
import { isEmpty, isEqual, toNumber } from "lodash";
import { FaAngleDown } from "react-icons/fa";
import '../assets/css/dropdown.css';
function SingleSelect(props) {
    const ref = useRef();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const inputLable = (props.inputLable ? props.inputLable : "");
    const [inputValue, setInputValue] = useState((props.inputValue ? props.inputValue : ""));
    const [errorMessage, setErrorMessage] = useState((props.errorMessage ? props.errorMessage : ""));
    const inputOptions= (props.inputOptions ? props.inputOptions : "");
    const displayMessage = (props.displayMessage ? props.displayMessage : "");
    const matchWith = (props.matchWith ? props.matchWith : "");
    const returnwith = (props.returnwith ? props.returnwith : "");
    const isOptional = (props.isOptional ? props.isOptional : "");
    const [selectedText, setSelectedText] = useState("");
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isMenuOpen]);
    useEffect(() => {
        if (!isOptional) {
            if (!isEmpty(getValueFromTheArray(inputValue))) {
                setErrorMessage("");
            }
            else {
                setErrorMessage(displayMessage);
            }
        }
        else {
            setErrorMessage("");
        }
        props.onValidate(errorMessage, inputValue);
    }, [inputValue]);
    const getValueFromTheArray = (key) => {
        if (!isEmpty(inputOptions) && !isEmpty(matchWith) && !isEmpty(returnwith)) {
            key = key.toString().toLowerCase();
            let co = inputOptions.find((i) => (!isEmpty(i[matchWith]) ? i[matchWith].toString().toLowerCase() : "") === key);
            return co ? (!isEmpty(co[returnwith]) ? co[returnwith] : "") : "";
        }
        else {
            return "";
        }
    }
    const setSelectBoxValue = (value) => {
        setInputValue(value);
    }
    return (
        <>
            <div className="custom-dropdown" ref={ref}>
                <label className="label labels">{inputLable}</label>
                <div className={"dropdown-wrapper " + (isMenuOpen ? "active" : "")}>
                    <div className="icon-wrapper">
                        <button className="toggle-action dropdown-button" onClick={() => setIsMenuOpen(oldState => !oldState)}>
                            {selectedText}<FaAngleDown className="toggle fa fa-chevron-down" />
                        </button>
                        <div className="dropdown-content">
                            <ul>
                                {!isEmpty(inputOptions) ?
                                    Object.keys(inputOptions).map(function (keyName, keyIndex) {
                                        if (isEqual(toNumber(inputOptions[keyName][matchWith]),toNumber(inputValue))) {
                                            if (!isEqual(selectedText,inputOptions[keyName][returnwith]))
                                                setSelectedText(inputOptions[keyName][returnwith]);
                                        }
                                        return (
                                            <li key={keyIndex} onClick={() => setSelectBoxValue(inputOptions[keyName][matchWith])}><input id={keyIndex} type="radio" name="test" defaultChecked={inputOptions[keyName][matchWith] === inputValue ? true : false} /><label htmlFor={keyIndex}>{inputOptions[keyName][returnwith]}</label></li>
                                        );
                                    })
                                    :
                                    null
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default SingleSelect;