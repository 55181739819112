import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from "lodash";
import apiUrls from "../shared/remote/Routes";
import Webhandler from "../shared/remote/Webhandler";
const webHandler = new Webhandler();
export default function ReceiveData(props) {
    let [returnData, setReturnData] = useState({url:'',isFirstLoad:false,message:"",data:{}});
    const location = useLocation();
    useEffect(() => {
        if(returnData.isFirstLoad) {
            invalidCallPage();
        }
            
    }, [returnData]);
    useEffect(() => {
        let url = location.pathname;
        let urlToken = url.substring(url.lastIndexOf("/") + 1);
        setReturnData({});
        returnData.url = urlToken;
        returnData.isFirstLoad = true;
        returnData.message = "Invalid call of this page";
        returnData.data = {};
        if (!isEmpty(urlToken)) {
            webHandler.fetchSimpleGetRequest(
                apiUrls.captureOrder + urlToken,
                {},
                (response) => {
                    returnData.message = "";
                    returnData.data = response.data;
                    setReturnData(returnData);
                },
                (error) => {
                    returnData.message = "api response error";
                    setReturnData(returnData);
                },
                () => { },
                ""
            );
        }
        else {
            setReturnData(returnData);
        }
    }, []);
    function invalidCallPage() {
        props.next({ ... returnData });
    }
    return (
        <>
        </>
    )
}
